<template>
	<div class="business">
		<div class="main">
			<div class="left-wrap">
				<p style="font-size: 14px;color: #333;font-weight: bold;">业务项目</p>
				<ul>
					<li
						:class="{ active: id === item.id }"
						@click="
							() => {
								id = item.id;
							}
						"
						v-for="item in parent"
						:key="item.id"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
			<div class="right-wrap">
				<div class="title">{{ $route.meta.title }}</div>
				<div style="display: flex;align-items: center;">
					<el-input style="width: 200px;margin-right: 10px;" size="small" placeholder="请输入字段名称" v-model="keyword"></el-input>
					<el-button size="small" class="btn-blue right" @click="handleAdd">添加字段</el-button>
				</div>
				<div class="table">
					<el-table
						:data="keyword !== '' ? children.filter(res => res.name.includes(keyword)) : children"
						v-loading="loading"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column prop="name" label="字段名称"></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button v-if="whiteList.every(val => val !== scope.row.name)" type="text" @click="handleEdit(scope.row)">编辑</el-button>
								<el-button v-if="whiteList.every(val => val !== scope.row.name)" type="text" @click="handleDel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!-- 添加字段 -->
		<add-dialog :parent="parent" :pid="id" :row="row" @refresh="getFieldList"></add-dialog>
	</div>
</template>

<script>
import { reqEnterCustomerFieldList, reqDelEnterCustomerField } from '@/api/index';
import AddDialog from './components/Add.vue';
export default {
	components: {
		AddDialog
	},
	data() {
		return {
			keyword: '',
			parent: [],
			children: [],
			id: '',
			loading: false,
			row: {},
			whiteList: ['未跟进', '跟进中', '已拒绝', '已成交']
		};
	},
	watch: {
		id(val) {
			const arr = this.parent.filter(res => res.id === val);
			this.children = arr[0].group;
		}
	},
	mounted() {
		this.getFieldList();
	},
	methods: {
		getFieldList() {
			this.loading = true;
			reqEnterCustomerFieldList({}).then(res => {
				this.parent = res.data;
				if (this.parent.length) {
					this.id = this.parent[0].id;
					this.children = this.parent[0].group;
				} else {
					this.id = '';
					this.children = [];
				}
				this.loading = false;
			});
		},
		handleAdd() {
			this.$bus.$emit('showDialog', 'add');
		},
		handleEdit(row) {
			this.$bus.$emit('showDialog', 'edit');
			this.row = row;
		},
		handleDel(row) {
			this.$confirm(`确认删除字段【${row.name}】?`, '删除字段', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$message({
						center: true,
						iconClass: 'el-icon-loading ',
						duration: 0,
						message: '正在删除中...'
					});
					reqDelEnterCustomerField({
						id: row.id,
						del: 1
					}).then(res => {
						this.$message.success(res.msg);
						this.getFieldList();
					});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.business {
	.main {
		display: flex;
		flex-shrink: 0;
		height: calc(100vh - 135px);
		.left-wrap {
			width: 170px;
			overflow-y: auto;
			overflow-x: hidden;
			border-radius: 10px;
			padding: 20px;
			border: 1px solid #b9c5e9;

			ul {
				font-size: 14px;
				color: #333;
				margin-top: 15px;
				li {
					height: 40px;
					margin: 10px auto;
					line-height: 40px;
					cursor: pointer;
					padding: 0 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.active {
						background-color: #e5ebfb;
						border-radius: 5px;
						color: #133ab3;
					}
				}
			}
		}
		.right-wrap {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 0 20px 20px;
			> div + div {
				margin-top: 15px;
			}
		}
	}
}
</style>
