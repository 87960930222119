<template>
	<el-dialog :close-on-click-modal="false" :title="type === 'add' ? '添加字段' : '编辑字段'" @open="handleOpen" :visible.sync="visible" width="400px" :before-close="handleClose">
		<el-form :key="new Date().getTime()" label-width="100px" :model="form" ref="formRef" size="small" :rules="rules">
			<el-form-item label="业务项目" prop="pid">
				<el-select :disabled="type === 'edit'" v-model="form.pid" placeholder="请选择业务项目">
					<el-option v-for="item in parent" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="字段名称" prop="name"><el-input maxlength="15" v-model="form.name" placeholder="请输入字段名称"></el-input></el-form-item>
		</el-form>
		<span slot="footer">
			<el-button size="small" class="btn-white" @click="handleClose()">取 消</el-button>
			<el-button size="small" class="btn-blue" @click="handleConfirm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { reqAddEnterCustomerField, reqEditEnterCustomerField } from '@/api/index';
export default {
	props: {
		parent: {
			type: Array,
			default: () => {
				return [];
			}
		},
		pid: {
			default: ''
		},
		row: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			visible: false,
			form: {
				pid: '',
				name: ''
			},
			rules: {
				pid: [{ required: true, message: '请选择业务项目', trigger: 'change' }],
				name: [{ required: true, message: '请输入字段名称', trigger: 'blur' }]
			},
			type: ''
		};
	},
	mounted() {
		this.$bus.$on('showDialog', type => {
			this.visible = true;
			this.type = type;
		});
	},
	destroyed() {
		this.$bus.$off('showDialog');
	},
	methods: {
		handleOpen() {
			if (this.pid) {
				this.form.pid = this.pid;
			}
			if (this.type === 'edit') {
				this.form.name = this.row.name;
			}
		},
		handleClose() {
			this.form = {
				name: '',
				pid: ''
			};
			this.$refs.formRef.clearValidate();
			this.visible = false;
		},
		handleConfirm() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					if (this.type === 'add') {
						this.add();
					} else if (this.type === 'edit') {
						this.edit();
					}
				} else {
					return false;
				}
			});
		},
		add() {
			reqAddEnterCustomerField({
				pid: this.form.pid,
				name: this.form.name
			}).then(res => {
				this.$emit('refresh');
				this.$message.success(res.msg);
				this.handleClose();
			});
		},
		edit() {
			reqEditEnterCustomerField({
				id: this.row.id,
				name: this.form.name
			}).then(res => {
				this.$emit('refresh');
				this.$message.success(res.msg);
				this.handleClose();
			});
		}
	}
};
</script>

<style lang="less" scoped>
.el-dialog {
	.el-form {
		.el-select,
		.el-input,
		.el-cascader {
			width: 200px;
		}
		.el-form-item {
			margin-bottom: 15px !important;
		}
		/deep/.el-form-item__error {
			padding-top: 4px;
		}
	}
}
</style>
